<template>
  <v-card class="elevation-x">
    <v-list-item-subtitle class="pl-4 pt-2 font-alt">Parcours professionel :</v-list-item-subtitle>
    <v-card-text>
      <v-timeline align-top dense>
        <v-timeline-item v-for="(item, i) in items" :key="i" :color="item.color" small>
          <v-row class="pt-1">
            <v-col cols="3" class="font-alt">
              {{ item.type }}
            </v-col>
            <v-col class="font-alt">
              <div v-html="item.text" />
              <div class="caption">
                {{ item.date }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ProfessionalInformations',
    data: () => ({
      items: [
        {
          type: 'Formation',
          text: '<strong class="warning--text text--darken-2">Spécialisation traitement du diabète </strong>',
          date: 'Centre du diabéte Valence, 2021',
          color: 'warning darken-2'
        },
        {
          type: 'Formation',
          text: '<strong class="warning--text text--darken-2">Formation prise en charge Avulsion dentaire </strong>',
          date: 'Centre de formation prise en charge, Valence 2021',
          color: 'warning darken-2'
        },
        {
          type: 'Diplome',
          text: '<strong class="primary--text">Diplome de médecine </strong>',
          date: 'Université Charles de Gaulle Lyon, 2020',
          color: 'primary'
        },
        {
          type: 'Diplome',
          text: '<strong class="primary--text">Classe préparatoire médecine </strong>',
          date: 'Université Charles de Gaulle Lyon, 2018',
          color: 'primary'
        }
      ]
    })
  }
</script>
